require("@rails/ujs").start();

$(function () {
  body = $("body");

  function anchorScrl() {
    $('a[href^="#"]').on("click", function () {
      var speed = 400;
      var href = $(this).attr("href");
      var target = $(href == "#" || href == "" ? "html" : href);
      var position = target.offset().top;
      $("html, body").animate({ scrollTop: position }, speed, "swing");
      return false;
    });
  }

  var delayHeight, winH, winW, footer_pos;

  function delayAnimeInt() {
    if (window.matchMedia("(min-width:767px)").matches) {
      delayHeight = 0;
    } else {
      delayHeight = 0;
    }
    delaySet();
    footer_pos = $("#footer").offset().top;
    delayAnime();
    scrlClass();
  }
  function delaySet() {
    var setElm = $(".anime");
    winH = $(window).height();
    setElm.each(function () {
      var setThis = $(this),
        obj = setThis.find(".obj"),
        elmHeight = setThis.height();
      for (var i = 0; i < obj.length; i++) {
        var delaytime = i * 200 + 300;
        obj.eq(i).css({ transitionDelay: delaytime + "ms" });
      }
    });
  }

  var scrTop = 1,
    scrl_before = $(window).scrollTop(),
    scrl_after;
  function delayAnime() {
    scrTop = $(window).scrollTop();
    var setElm = $(".anime");
    setElm.each(function () {
      var setThis = $(this),
        elmHeight = setThis.height(),
        elmTop = setThis.offset().top;
      if (scrTop > elmTop - winH + delayHeight && scrTop < elmTop + elmHeight) {
        if (!setThis.hasClass("active")) {
          setThis.addClass("active");
        }
      }
    });
    scrlClass();
  }

  function scrlClass() {
    if (scrTop === 0) {
      body.addClass("scrl_top");
      body.removeClass("scrl");
    } else {
      body.removeClass("scrl_top");
      body.addClass("scrl");
    }
    if (scrTop > winH) {
      if (!body.hasClass("scrl_oneview")) {
        body.addClass("scrl_oneview");
      }
    } else {
      if (body.hasClass("scrl_oneview")) {
        body.removeClass("scrl_oneview");
      }
    }
    var bottom_h = 30;
    if (scrTop > footer_pos - winH + bottom_h) {
      if (!body.hasClass("scrl_end")) {
        body.addClass("scrl_end");
      }
    } else {
      if (body.hasClass("scrl_end")) {
        body.removeClass("scrl_end");
      }
    }

    if (window.matchMedia("(max-width:767px)").matches) {
      scrl_after = scrTop - 100;
    } else {
      scrl_after = scrTop - 100;
    }
    if (scrl_after <= 0) {
      body.removeClass("scrl_down");
    } else {
      if (scrl_before < scrl_after) {
        body.addClass("scrl_down");
      } else if (scrl_before > scrl_after) {
        body.removeClass("scrl_down");
      }
    }
    scrl_before = scrl_after;
  }

  delayAnimeInt();
  $(window).on("scroll", function () {
    delayAnime();
  });

  anchorScrl();
});
